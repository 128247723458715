import deepMerge from "../utils/DeepMerge";

const defaultConfig = {
  apiUrl: "http://localhost:8001/api/",
  helpMenuItem: "https://leeward-myrtle-40d.notion.site/Centrum-Psychoterapii-Calmeo-04b4b5b692db4d59bb5e55baf6bdd287",
  infaktInvoiceUrl: "https://app.infakt.pl/app/faktury/",
  staticMenuItems: [
    {
      name: "Documents", children: [
        { name: "Specialists", link: "https://docs.google.com/document/d/19J11gW0MsaYv1FtV2Rv1jL5KiXpffUWc/edit" },
        { name: "Regulations", link: "https://calmeo-static.s3.amazonaws.com/static/calmeo/Regulamin_v3.pdf" },
        { name: "RODO", link: "https://calmeo-static.s3.amazonaws.com/static/calmeo/RODO_v2.pdf" },
        { name: "Form 1", link: "https://calmeo-static.s3.amazonaws.com/static/calmeo/Formularz_1.pdf" },
        { name: "Form 2", link: "https://calmeo-static.s3.amazonaws.com/static/calmeo/Formularz_2.pdf" },
      ]
    }
  ],
  timeZone: "Europe/Warsaw",
  ownEventsCoordinatorUserIds: [34],
};

const env = process.env.NODE_ENV || "development";
const specificConfig = require(`./${env}.js`).default;

export default deepMerge(defaultConfig, specificConfig);
