import { useContext, useState } from "react";

import config from "../../config";
import translate from "../../utils/translate";
import urlPatterns from "../../utils/UrlManager/urlPatterns";

import CashSummary from "../../components/CashSummary";
import TherapistSelect from "../../components/TherapistSelect";
import YearMonthSelect from "../../components/YearMonthSelect";
import IncomeSummary from "../../components/IncomeSummary";
import LocationBalanceSummary from "../../components/LocationBalanceSummary";

import AuthContext from "../../contexts/AuthContext";
import { PERMISSION } from "../../utils/constants";

import "./Reports.scss";


const Reports = () => {
  const [therapistKwargs, setTherapistKwargs] = useState({});
  const { user, hasPermission, apiClient } = useContext(AuthContext);
  const [dateKwargs, setDateKwargs] = useState({});

  const reports = [
    {
      name: translate("Event report"),
      url: urlPatterns.api.reports.eventRegistry,
    },
    {
      name: translate("Time report"),
      url: urlPatterns.api.reports.timeRegistry,
      agreement_type: "contract_of_mandate",
    },
    {
      name: translate("Receipt report"),
      url: urlPatterns.api.reports.receiptRegistry,
      agreement_type: "contract_of_mandate",
      period: {
        start: { year: 2000 },
        end: { year: 2021 }
      },
    },
    {
      name: translate("Receipt report"),
      url: urlPatterns.api.reports.receiptV2Registry,
      agreement_type: "contract_of_mandate",
      period: {
        start: { year: 2022 },
        end: { year: 2200 }
      },
    },
    {
      name: translate("Salary report"),
      url: urlPatterns.api.reports.salaryRegistry,
      permission_required: PERMISSION.CAN_MANAGE_TRANSACTIONS,
    },
    {
      name: translate("Salary file"),
      url: urlPatterns.api.reports.salaryFile,
      permission_required: PERMISSION.CAN_MANAGE_TRANSACTIONS,
    },
  ];

  return (
    <div className="container-reports">
      <TherapistSelect
        setAdditionalKwargs={setTherapistKwargs}
        additionalKwargs={therapistKwargs}
        therapistKwargName="user_id"
      />

      <YearMonthSelect
        setAdditionalKwargs={setDateKwargs}
        additionalKwargs={dateKwargs}
      />

      {reports
        .filter(
          (report) =>
            !report.agreement_type ||
            hasPermission(PERMISSION.CAN_MANAGE_TRANSACTIONS) ||
            !user.employee ||
            user.employee.agreement_type === report.agreement_type
        )
        .filter(report =>
          !report.period ||
          (
            report.period.start.year <= dateKwargs.year &&
            report.period.end.year >= dateKwargs.year
          )
        )
        .filter(report => !report.permission_required || hasPermission(report.permission_required))
        .map((report, index) => (
          <div key={`${report.url}-${index}`} className="report-item">
            {report.name} -{" "}
            <a
              href={`${config.apiUrl}${report.url
                .replace(":year", dateKwargs.year)
                .replace(":month", dateKwargs.month)}${
                therapistKwargs.user_id
                  ? `?user_id=${therapistKwargs.user_id}&token=${apiClient.authToken}`
                  : `?token=${apiClient.authToken}`
              }`}
            >
              {translate("download")}
            </a>
          </div>
        ))}
      {dateKwargs.month && dateKwargs.year ? <IncomeSummary month={dateKwargs.month} year={dateKwargs.year} user_id={therapistKwargs.user_id}/> : null}
      <hr/>
      {dateKwargs.month && dateKwargs.year ? <CashSummary month={dateKwargs.month} year={dateKwargs.year} forced_user_id={user.id}/> : null}
      {dateKwargs.month && dateKwargs.year ? <LocationBalanceSummary month={dateKwargs.month} year={dateKwargs.year}/> : null}
    </div>
  );
};

export default Reports;
