import { useContext, useEffect, useState } from "react";
import config from "../../config";
import AuthContext from "../../contexts/AuthContext";
import CoordinatedEventsModal from "../CoordinatedEventsModal";
import translate from "../../utils/translate";
import { CONTRACT_TYPE } from "../../utils/constants";

import './IncomeSummary.css';


const IncomeSummary = ({ month, year, user_id }) => {
  const [incomeData, setIncomeData] = useState({});
  const { apiClient, user } = useContext(AuthContext);

  useEffect(() => {
    apiClient
      .get("api.reports.incomeSummary", { month, year }, { user_id })
      .then((res) => setIncomeData(res));
  }, [apiClient, month, year, user_id]);

  return incomeData.agreement_type ? <div className="component-income-summary">
    <div>{ incomeData.agreement_type == CONTRACT_TYPE.CONTRACT_OF_MANDATE ? translate("Income value") : translate("Income this month")}: <b>{incomeData.income} zł</b></div>
    { incomeData.coordinated_count && !config.ownEventsCoordinatorUserIds.includes(user.id) ? <div className="margin-top">{translate("Coordinated event count")}: <b>{incomeData.coordinated_count}</b> <CoordinatedEventsModal year={year} month={month} user_id={user_id}/></div> : null }
  </div> : null;
};

export default IncomeSummary;
