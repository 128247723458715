import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown"
import { LinkContainer } from "react-router-bootstrap";
import urlPatterns from "../../utils/UrlManager/urlPatterns";
import AuthContext from "../../contexts/AuthContext";
import translate from "../../utils/translate";
import config from "../../config";
import { PERMISSION } from "../../utils/constants";
import { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import { useHistory, useLocation } from "react-router-dom";

import './Navigation.scss';


moment.tz.setDefault(config.timeZone);

const Navigation = () => {
  const [notAssignedTransactions, setNotAssignedTransactions] = useState([]);
  const [missingTransactionEvents, setMissingTransactionEvents] = useState([]);
  const { apiClient, hasPermission, user } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();

  const navigateTo = (pathname, search, hash = '') => history.push({ pathname, search, hash });

  useEffect(() => {
    apiClient
      .get('api.events.listWithoutTransaction', {}, { limit: 100 })
      .then(response => setMissingTransactionEvents(response.results));
  }, [apiClient, user]);

  useEffect(() => {
    if (hasPermission(PERMISSION.CAN_MANAGE_TRANSACTIONS)) {
      apiClient
        .get('api.transactions.listNotAssigned', {}, { limit: 100 })
        .then(response => setNotAssignedTransactions(response.results));
    }
  }, [apiClient, user]);

  useEffect(() => setTimeout(() => {
    const element = document.getElementById(location.hash.replace('#', ''));
    const topNavbar = document.getElementById('MainNavbar');
    if (element) {
      window.scrollTo({
        behavior: 'smooth',
        top: window.pageYOffset + element.getBoundingClientRect().top - (topNavbar.offsetHeight + 10)
      });
    }
  }, 1000), [location]);

  return (
    <Navbar collapseOnSelect expand="sm" bg="secondary" sticky="top" id="MainNavbar" className="component-navigation">
      <LinkContainer to={urlPatterns.fe.home}>
        <Navbar.Brand>Calmeo</Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
        <Nav>
          <AuthContext.Consumer>
            {({ isLoggedIn, hasPermission, logout }) => (
              <>
                {!isLoggedIn() ? (
                  <LinkContainer to={urlPatterns.fe.login}>
                    <Nav.Link>{translate("Login")}</Nav.Link>
                  </LinkContainer>
                ) : (
                  <>
                    {hasPermission(PERMISSION.CAN_MANAGE_TRANSACTIONS) || hasPermission(PERMISSION.CAN_MANAGE_COLLECTED_CASH) || hasPermission(PERMISSION.CAN_DELEGATE_EVENTS) || hasPermission(PERMISSION.CAN_MANAGE_INVOICES) ? (
                      <NavDropdown title={translate("Admin")}>
                        {hasPermission(PERMISSION.CAN_MANAGE_TRANSACTIONS) ? (
                          <>
                            <NavDropdown.Item onClick={() => navigateTo(urlPatterns.fe.transactions)}>
                              {translate("Transactions")}
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => navigateTo(urlPatterns.fe.costs)}>
                              {translate("Costs")}
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => navigateTo(urlPatterns.fe.uploadTransactions)}>
                              {translate("Upload transactions")}
                            </NavDropdown.Item>
                          </>
                        ) : null}
                        {hasPermission(PERMISSION.CAN_MANAGE_COLLECTED_CASH) ? (
                          <NavDropdown.Item onClick={() => navigateTo(urlPatterns.fe.manageCollectedCash)}>
                            {translate("Manage cash")}
                          </NavDropdown.Item>
                        ) : null}
                        {hasPermission(PERMISSION.CAN_DELEGATE_EVENTS) ? (
                          <NavDropdown.Item onClick={() => navigateTo(urlPatterns.fe.delegatedEvents)}>
                            {translate("Delegated events")}
                          </NavDropdown.Item>
                        ) : null}
                        {hasPermission(PERMISSION.CAN_MANAGE_INVOICES) ? (
                          <NavDropdown.Item onClick={() => navigateTo(urlPatterns.fe.invoiceReport)}>
                            {translate("Invoice report")}
                          </NavDropdown.Item>
                        ) : null}
                      </NavDropdown>
                    ) : null}

                    {notAssignedTransactions.length > 0 ? (
                      <NavDropdown title={translate("Not assigned transactions")}>
                        {notAssignedTransactions.map((notAssignedTransaction) =>
                          <NavDropdown.Item key={`NotAssignedTransaction-${notAssignedTransaction.id}`}
                                            onClick={() => navigateTo(
                                              urlPatterns.fe.transactions,
                                              `transaction_date__gte=${notAssignedTransaction.transaction_date}&transaction_date__lte=${notAssignedTransaction.transaction_date}`,
                                              `TransactionId-${notAssignedTransaction.id}`
                                            )}>
                            {notAssignedTransaction.contractor_details}, {notAssignedTransaction.amount} zł
                          </NavDropdown.Item>
                        )}
                      </NavDropdown>
                    ) : null}

                    {missingTransactionEvents.length > 0 ? (
                      <LinkContainer to={urlPatterns.fe.unpaidEvents}>
                        <Nav.Link>{translate("Missing payments")}</Nav.Link>
                      </LinkContainer>
                    ) : null}

                    <LinkContainer to={urlPatterns.fe.reports}>
                      <Nav.Link>{translate("Reports")}</Nav.Link>
                    </LinkContainer>

                    {config.staticMenuItems.map((mainItem, mainItemIndex) =>
                      <NavDropdown title={translate(mainItem.name)} key={`NavigationMainItem-${mainItemIndex}`}>
                        {mainItem.children.map((item, itemIndex) =>
                          <NavDropdown.Item key={`NavigationItem-${itemIndex}`}
                                            href={`${item.link}?t=${(new Date()).getTime()}`} target="_blank">
                            {translate(item.name)}
                          </NavDropdown.Item>
                        )}
                      </NavDropdown>
                    )}

                    <Nav.Link href={config.helpMenuItem} target="_blank">
                      {translate("Help")}
                    </Nav.Link>

                    <Nav.Link onClick={() => logout()}>
                      {translate("Logout")}
                    </Nav.Link>
                  </>
                )}
              </>
            )}
          </AuthContext.Consumer>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;
