const translations = Object.freeze({
  card: "Karta",
  cash: "Gotówka",
  transfer: "Przelew lub blik",
  stripe: "Stripe",
  Calendar: "Kalendarz",
  "No events in selected date.": "Brak sesji w wybranym zakresie dat.",
  "Drag 'n' drop some files here, or click to select files":
    "Przeciągnij plik tutaj lub kliknij by wybrać z dysku",
  "Drop the files here...": "Upuść plik tutaj...",
  "Upload transactions": "Zaimportuj transakcje",
  "Transactions successfully imported.": "Transakcje zaimportowane.",
  "Error occured, try again": "Wystąpił błąd, spróbuj ponownie.",
  Transactions: "Transakcje",
  "Error loading items.": "Wystąpił błąd w pobieraniu danych.",
  "No transactions in selected date.":
    "Brak transakcji w wybranym zakresie dat.",
  Ignore: "Pomiń",
  Unignore: "Przywróć",
  Contributor: "Wpłacający",
  "Transfer title": "Tytuł",
  "Search for an event...": "Wyszukaj wizytę...",
  "delete transaction": "usuń",
  "No events found.": "Nie znaleziono wizyt.",
  "Type to search...": "Wpisz nazwisko pacjenta...",
  "Are you sure you want to remove this event from transaction?":
    "Czy na pewno chcesz usunąć tą wizytę z tej transakcji?",
  CANCELLED: "ANULOWANA",
  "Choose therapist": "Wybierz terapeutę",
  "Force transaction assigned status": "Wymuś poprawne oznaczenie transakcji",
  "Accounting date": "Data księgowania",
  "Transaction accounted at the office": "Transakcja zaksięgowana w gabinecie",
  Reports: "Raporty",
  "Event report": "Rejestr świadczeń",
  "Time report": "Ewidencja czasu pracy",
  "Receipt report": "Rachunek",
  "Salary report": "Raport wynagrodzeń",
  download: "pobierz",
  "Show cancelled events": "Pokaż anulowane i pominięte wizyty",
  "Hide cancelled events": "Ukryj anulowane i pominięte wizyty",
  "Documents": "Dokumenty",
  "Specialists": "Lista specjalistów",
  "Regulations": "Regulamin",
  "COVID": "Oświadczenie COVID",
  "Form 1": "Formularz 1",
  "Form 2": "Formularz 2",
  "Tax to pay to tax office": "Podatek do zapłaty do US",
  "Cash to be collected this month": "Gotówka do pobrania w danym miesiącu",
  "Partly paid in cash": "Kwota częściowo w gotówce",
  "for_tax_number": "faktura NIP",
  "for_receipt": "faktura do paragonu",
  "Missing payments": "Nieoplacone wizyty",
  "withdrawn": "pobrano",
  "Add collected cash": "dodaj",
  "Collected cash": "Pobrana gotówka w zł",
  "Save": "Zapisz",
  "Filter assigned transactions": "Odfiltruj przypisane transakcje",
  "Skip this event in reports.": "Pomiń w raportach",
  "Skipped in report": "Pominięte w raportach",
  "Patient missed the event but he is obligated to pay.": "Pacjent nieobecny, wymagana opłata",
  "Not assigned transactions": "Nieprzypisane transakcje",
  "Salary file": "Plik przelewów",
  "Logout": "Wyloguj",
  "Help": "Pomoc",
  "Invoice report": "Raport faktur",
  "accounted": "zaksięgowano",
  "Invoice for this event already generated.": "Faktura została wystawiona",
  "Filter out sent invoices": "Odfiltruj wysłane faktury",
  "Forced reporting date": "Wymuszona data raportu",
  "Receipt number": "Numer paragonu do faktury",
  "invoice_per_event": "pojedyncza",
  "invoice_cumulative": "zbiorcza",
  "Are you sure you want to generate invoice for:": "Czy na pewno chcesz wygenerować i wysłać fakturę pacjentowi:",
  "See invoice": "Zobacz fakturę",
  "Generate invoice": "Wygeneruj fakturę",
  "Send invoice": "Wyślij fakturę",
  "Invoice sent": "Wysłano fakturę",
  "This event is last in this location.": "Ta wizyta jest ostatnią w lokalizacji",
  "Income this month": "Kwota na fakturze",
  "Income value": "Wynagrodzenie w danym miesiącu",
  "collected so far": "koordynator pobrał do tej pory",
  "in envelope should be": "w kopercie powinno być",
  "to collect": "do pobrania",
  "katowice": "Katowice",
  "tychy": "Tychy Bacha",
  "tychy_bielska": "Tychy Bielska",
  "online": "Online",
  "myslowice": "Mysłowice",
  "all_locations": "Wszystkie lokalizacje",
  "income_prediction": "Predykcja wyniku",
  "No location": "Nie przypisane do lokalizacji",
  "wire_transfer": "Przelewy",
  "Show all transactions": "Wszystkie transakcje",
  "Event count": "Liczba wizyt",
  "Income": "Przychód",
  "Costs": "Koszty",
  "Balance": "Bilans",
  "Balance summary": "Podsumowanie miesiąca",
  "Delegated events": "Audio",
  "Recording shared": "Audio wysłane",
  "Filter already recorded feedbacks": "Odfiltruj przesłane audio",
  "Selected therapist": "Terapeuta",
  "Query": "Wyszukaj...",
  "Create ereceipt": "Wystaw paragon",
  "Select payment method": "Wybierz metodę płatności",
  "See ereceipt": "Zobacz paragon",
  "Attachments": "Audio i pliki",
  "Upload files": "Wgraj audio lub plik",
  "Manage cash": "Zarządzaj gotówką",
  "Cash summary": "Podsumowanie stanu gotówki terapeutów",
  "Choose description": "Wybierz opis wgrywanego pliku",
  "Coordinated event count": "Liczba wizyt pozyskanych pacjentów",
  "coordinated event details": "szczegóły",
  "coordinated events": "Wizyty pozyskanych pacjentów",
  "Collected cash summary": "Podsumowanie stanu gotówki koordynatorów",
  "Collected cash amount": "Gotówka pobrana",
  "Deposited cash amount": "Gotówka wpłacona",
  "Cash pending deposit": "Gotówka do wpłaty",
});

const translate = (text) => translations[text] || text;

export default translate;
